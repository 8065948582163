import { Injectable } from '@angular/core';
import { AppState } from '@core/store';
import { Store } from '@ngrx/store';
import { SciAccesosService } from 'app/services/config/sciAccesos.service';
import { ElementsEnlace } from './redirect-utils.service';
import { ComponentStore } from '@ngrx/component-store';
import { environment } from 'environments/environment';
import { oficinaSelected } from '@core/store/selectors/oficinas.selectors';
import { switchMap, tap } from 'rxjs/operators';
import { SciFormularioAccionListado } from '@core/store/models/SciFormularioAccionListado';
import { EnumSistema } from '~shared/enums/EnumSistema';
import { EnumForm } from '~shared/enums/EnumForm';
import { EnumPermissionDefault } from '~shared/enums/EnumPermission';

interface EnlacesRedirecciones {
	ejecutivo: ElementsEnlace;
}
export type KeyEnlacesRedireccionesConfiguracionInterna = keyof EnlacesRedirecciones;

const initState: EnlacesRedirecciones = {
	ejecutivo: {
		title: 'Ejecutivos',
		isDisabled: false,
		url: `${environment.URL_CONFIG_INTERNA}/ejecutivo`,
	},
};

@Injectable({ providedIn: 'root' })
export class EnlacesRedireccionConfigStore extends ComponentStore<EnlacesRedirecciones> {
	constructor(private readonly _sciaccesosService: SciAccesosService, private readonly _store: Store<AppState>) {
		super(initState);
	}

	readonly loadState = this.effect(() => {
		return this._store.select(oficinaSelected).pipe(
			switchMap((oficina) => this._sciaccesosService.findAllByCodigoSistemaAndIdOficina(EnumSistema.CONFIG_INTERNA, Number(oficina?.idOficina))),
			tap((formularioAcciones: SciFormularioAccionListado[]) => {
				console.log('form: ', formularioAcciones);
				this.listEnlaceRegireccion.ejecutivo.isDisabled = this.validAccesoFormulario(formularioAcciones, EnumForm.Ejecutivo);
			})
		);
	});

	get listEnlaceRegireccion(): EnlacesRedirecciones {
		return this.get();
	}

	validAccesoFormulario(formularioAcciones: SciFormularioAccionListado[], enumForm: EnumForm): boolean {
		return !formularioAcciones.find((formAccion) => formAccion.codigo === enumForm)?.acciones.some((accion) => accion.codigo === EnumPermissionDefault.Listar);
	}
}
