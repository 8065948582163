import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { AppState } from '@core/store';
import { Store } from '@ngrx/store';
import { switchMap, tap } from 'rxjs';
import { ElementsEnlace } from './redirect-utils.service';
import { SciAccesosService } from 'app/services/config/sciAccesos.service';
import { oficinaSelected } from '@core/store/selectors/oficinas.selectors';
import { EnumSistema } from '~shared/enums/EnumSistema';
import { EnumForm } from '~shared/enums/EnumForm';
import { SciFormularioAccionListado } from '@core/store/models/SciFormularioAccionListado';
import { EnumPermissionDefault } from '~shared/enums/EnumPermission';
import { environment } from 'environments/environment';

interface EnlacesRedirecciones {
	nave: ElementsEnlace;
	contratoTransporte: ElementsEnlace;
}
export type KeyEnlacesRedireccionesCarga = keyof EnlacesRedirecciones;

const initState: EnlacesRedirecciones = {
	nave: {
		title: 'Nave',
		isDisabled: false,
		url: `${environment.URL_CARGA}/control/nave-buque`,
	},
	contratoTransporte: {
		title: 'Contrato de transporte',
		isDisabled: false,
		url: `${environment.URL_CARGA}/control/contrato-transporte`,
	},
};

@Injectable({ providedIn: 'root' })
export class EnlacesRedireccionCargaStore extends ComponentStore<EnlacesRedirecciones> {
	constructor(private _sciaccesosService: SciAccesosService, private _store: Store<AppState>) {
		super(initState);
	}

	readonly loadState = this.effect(() => {
		return this._store.select(oficinaSelected).pipe(
			switchMap((oficina) => this._sciaccesosService.findAllByCodigoSistemaAndIdOficina(EnumSistema.CARGA, Number(oficina?.idOficina))),
			tap((formularioAcciones: SciFormularioAccionListado[]) => {
				this.listEnlaceRegireccion.nave.isDisabled = this.validAccesoFormulario(formularioAcciones, EnumForm.Nave);
				this.listEnlaceRegireccion.contratoTransporte.isDisabled = this.validAccesoFormulario(formularioAcciones, EnumForm.ContratoTransporte);
			})
		);
	});

	get listEnlaceRegireccion(): EnlacesRedirecciones {
		return this.get();
	}

	validAccesoFormulario(formularioAcciones: SciFormularioAccionListado[], enumForm: EnumForm): boolean {
		return !formularioAcciones.find((formAccion) => formAccion.codigo === enumForm)?.acciones.some((accion) => accion.codigo === EnumPermissionDefault.Listar);
	}
}
